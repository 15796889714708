import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';

import imageAtrhosetherapie from '../../images/Atrhosetherapie-min.jpg';
import imageSchmerztherapie from '../../images/Schmerztherapie (unsplash)-min.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Therapien | Orthopädie Dr. Schubert" description="Orthopädische Therapien ✓ Notfall-Sprechstunde ✓ Schmerztherapie ✓ Einlagen ✓ Stoßwellentherapie ✓ Termine online vereinbaren ✓" />
    <div className="greenContainer">
      <ul>
        <li className="active"><Link to="/therapien/klassisch">Klassisch</Link></li>
        <li><Link to="/therapien/innovativ">Innovativ</Link></li>
        <li><Link to="/therapien/integrativ">Integrativ</Link></li>
      </ul>
    </div>
    <Section dark>
      <Container>
        <Headline h1 light subtitle="Klassisch / Innovativ / Integrativ">Therapien</Headline>

        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Notfall-Sprechstunde -
                {' '}
                <i>jeden Tag</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Schmerzen sind nicht planbar.
                <br />
                <br />
                Deshalb bieten wir täglich eine Notfallsprechstunde für unsere Patienten an.
                Wir möchten Sie bitten, wenn möglich, den Termin vorher online zu buchen oder telefonisch bzw. gerne auch per Email zu erfragen.
                <br />
                Bitte haben Sie Verständnis dafür, dass im Rahmen eines Notfalltermins keine allumfassende Behandlung erfolgen kann. Wir werden trotzdem alles tun, um Ihr akutes Problem zu lösen und weiterführende Untersuchungen dann zeitnah terminieren.

              </p>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
          <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Arthrose- und Knorpelschutztherapie -
                {' '}
                <i>gegen das Altern</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Row>
                <Col xs={12} sm={6}>
                  <p>
                    Bei der Arthrose (Gelenkverschleiß) ist der Gelenkknorpel abgenutzt oder beschädigt. Durch die Abnutzung schmerzen die Gelenke, insbesondere beim Losgehen und Treppenlaufen. Nicht selten kommt es unter stärkerer Belastung zu einer Gelenkschwellung.
                    <br />
                    <br />
                    Mit fortschreitendem Alter leiden früher oder später die meisten Menschen unter Arthrose. Knie, Hüfte, Finger und Wirbelsäule sind die dabei am häufigsten betroffenen Gelenke.
                    <br />
                    <br />
                    Auch wenn Arthrose nicht heilbar ist, kann ihr Fortschreiten häufig verlangsamt und die Beschwerden deutlich gebessert werden.
                    <br />
                    <br />
                    Schonung und Kühlung können anfangs ausreichen, die Beschwerden zu verringern. Auch physikalische Massnahmen, Taping oder Akupunktur tragen zur Schmerzlinderung bei.
                    <br />
                    <br />
                    Die effektivste Form der konservativen Therapie stellen heutzutage die Gelenkinjektionen/ Spritzen dar. In unserer Praxis wird diese spezielle Technik unter höchstmöglicher Sicherheit steril durchgeführt.
                    <br />
                    Wir setzen alle modernen Verfahren mit Hyaluron, Eigenblut (ACP/PRP) auch als Hybrid- oder Kombinationstherapie (Regenlab: Hyaluron mit Eigenblut) sowie Homöopathika ein.
                    <br />
                    <br />
                    Durch meine über 20 jährige Erfahrung und über 100.000 durchgeführten Injektionen können Sie sich sicher und gut aufgehoben fühlen.
                  </p>
                </Col>
                <Col xs={12} sm={6}><img src={imageAtrhosetherapie} alt="Knorpelschutztherapie" /></Col>

              </Row>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
          <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Schmerztherapie -
                {' '}
                <i>klassisch bewährt und ganzheitlich modern</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Row>
                <Col xs={12} sm={6}>
                  <p>
                    Schmerzen haben viele Menschen, manche täglich. Wir Mediziner unterscheiden beim Schmerz zwei Kategorien: den akuten, zeitlich begrenzten Schmerz sowie den chronischen mit einer Dauer länger als 6 Monate.
                    <br />
                    <br />
                    Den akuten Schmerz wie zum Beispiel nach Verletzungen oder bei einem „Hexenschuss“ kennen wir alle. Wollen wir ihn ausschalten, können wir entweder schnell und intensiv oder sanft behandeln, je nach Bedürfnis.
                    <br />
                    <br />
                    Setzen wir Medikamente ein, bildet das von der Weltgesundheitsorganisation (WHO) entwickelte Stufenschema die Grundlage jeder individuellen Schmerztherapie.
                    <br />
                    <br />
                    Bei chronischen Schmerzen reicht dieses zumeist nicht. Wenn Schmerzen lange anhalten, bildet sich im zentralen Nervensystem ein Schmerzgedächtnis aus. Der Schmerz wird abgespeichert, verstärkt wahrgenommen und schon bei kleinen Reizen (Dehnung/Berührung) empfunden.
                    <br />
                    Hier gilt es, ein sinnvolles Konzept aus vielschichtigen Behandlungen (Medikamente/ physikalische, physiotherapeutische und psychologische Verfahren) zu entwickeln. Dieser moderne Ansatz nennt sich multimodale Schmerztherapie. Ziel der Therapie ist es, nicht nur den Schmerz zu nehmen, sondern auch den Organismus wieder ins Gleichgewicht zu bringen und Selbstheilungskräfte anzuregen.
                    <br />
                    <br />
                    Wir bieten Ihnen alle Therapieformen: klassisch bewährt und ganzheitlich modern.
                    <br />
                    <br />
                    Bitte sprechen Sie uns an, wir werden Sie umfassend beraten und den bestmöglichen Weg mit Ihnen finden.
                  </p>
                </Col>
                <Col xs={12} sm={6}><img src={imageSchmerztherapie} alt="Schmerztherapie" /></Col>

              </Row>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
          <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Einlagen -
                {' '}
                <i>auf Wolken laufen</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Orthopädische Einlagen stützen und korrigieren nicht nur Ihren Fuss, sie können auch Beinlängenunterschiede ausgleichen, über bestimmte Druckpunkte die Muskulatur aktiv stimulieren und Beinachsfehlstellungen vermindern.
                <br />
                <br />
                Einlagen sollen in alle Schuhe passen.
                <br />
                <br />
                Moderne Fertigungstechniken ermöglichen hierfür individuelle Lösungen: Sonderanfertigungen für Pumps, sportartspezifische Einlagen für Golf oder Fussball, schweissresorbierend oder thermisch kälteprotektiv für den Skischuh.
                <br />
                <br />
                Wenn Sie es wünschen, können wir Ihre Daten an einen Einlagenhersteller übermitteln oder Empfehlungen aussprechen.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
          <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Chirotherapie -
                {' '}
                <i>Heilen mit den Händen</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Gelenke können völlig intakt sein und dennoch schmerzen oder in ihrer Funktion gestört sein. Es liegt somit kein strukturelles (im Sinne eines z.B abgenutzten Gelenkes), sondern ein funktionelles Problem (z.B. eine Blockade) vor.
                <br />
                <br />
                Mit Hilfe der manuellen Medizin oder  Chirotherapie können wir die gestörte Gelenkbeweglichkeit durch sanfte und häufig wiederholte Dehnungsbewegungen oder auch durch eine sehr kurze, schnelle Behandlungstechnik (Impuls) wiederherstellen.
                <br />
                Oftmals kann hierdurch eine sofortige Schmerzfreiheit erreicht werden.
                <br />
                <br />
                Wir zeigen Ihnen gerne Übungen für zuhause, die Sie vorbeugend durchführen können, um erneute Schmerzen zu vermeiden.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
          <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Stoßwellentherapie (ESWT) -
                {' '}
                <i>mit Energie gegen den Schmerz</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>

                Die Stoßwellentherapie ist eine seit den 90er Jahren weltweit bewährte Behandlungsmethode. Anfänglich wurde sie bei der „Kalkschulter“, Fersensporn- und Tennisellenbogenbeschwerden eingesetzt. Später zeigten sich auch Erfolge bei verzögerter Knochenbruchheilung und allen Sehnenansatzreizungen.
                <br />
                <br />
                Das Prinzip beruht auf Druckwellen, die punktgenau in das betroffene Schmerzgebiet übertragen werden. Im Zielbereich erzeugen die Druckwellen eine Mehrdurchblutung und gesteigerte Zellteilung, wodurch Heilungsprozesse in Gang gesetzt werden.

              </p>
            </AccordionItemPanel>
          </AccordionItem>

        </Accordion>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
